/*================================================================================
	Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
	Version: 2.0
	Author: PIXINVENT
	Author URL: http://www.themeforest.net/user/pixinvent
================================================================================

NOTE:
------
PLACE HERE YOUR OWN SCSS CODES AND IF NEEDED, OVERRIDE THE STYLES FROM THE OTHER STYLESHEETS.
WE WILL RELEASE FUTURE UPDATES SO IN ORDER TO NOT OVERWRITE YOUR STYLES IT'S BETTER LIKE THIS.  */
.parent{ margin: auto;}
.myHeight{ height: 100vh; display: flex; align-items: center; text-align: center;}
.myCapitalized { text-transform: capitalize}
.textColor{ color: #009EDB !important;}
.chart-wrapper{
  width: 330px !important;
}

.history-text-color {
  color: #4d4d4d !important;
}

.profile {
//   background-color: #db2c52 !important;
  background-color: #f4fbfe !important;
}

// @media print {
// 	.profile{
//         background-color: #f4fbfe;
// 	}
  
// 	body{
//       -webkit-print-color-adjust: exact;
// 	}
// }

// @media print {
//    body {
//       -webkit-print-color-adjust: exact;
//    }
// }

/* Location analytics select box */
.w-40{
  width: 35% !important;
}

/* Bar chart analytics */
// .barCharHeight{
//   height: 50% !important;
// }

//font
.fs-12{
	font-size: 12px !important;
}

// pieLegends
.pieLegends{
	height: 18px !important;
	width: 18px !important;
	border-radius: 100%;
}

.bg-btn-analytics{
	background-color: #cbe0e9;
}

// Submission History page
.historoyDropdown{
	transform: translate(-120px, -25px) !important;
}

/* hide pdf white space */
.react-pdf__Page__annotations{
  display: none !important;
}

.react-pdf__Page__textContent{
  display: none !important;
}

.ql-editor {
  overflow-y: scroll !important;
  resize: vertical !important;
  min-height: 15em !important;
}

.quill {
	overflow: scroll !important;
}

.swal2-confirm {
	margin-right: 1.5rem !important;
}

.robotoFont {
	font-family: 'Roboto', sans-serif !important;
	color:#272727 !important
}

.ql-editor > * {
	// color: red !important;
	page-break-inside: auto !important;
}

.bg-supply-chain{
	background-color: #F7F8F8 !important;
}

.bg-ESG{
	background-color: #000 !important;
	color: #fff;
}

.bg-Environment{
	background-color: #38761D !important;
	color: #fff;
}

.bg-Social{
	background-color: #F1C232 !important;
	color: #000;
}

.bg-Governance{
	background-color: #00F !important;
	color: #fff;
}

.bg-green{
	background-color: #93C47D !important;
	// background-color: #38761D !important;
	color: #fff;
}

.bg-yellow{
	background-color: #F1C232 !important;
	color: #000;
}

.bg-blue{
	background-color: #00F !important;
	color: #fff;
}

.bg-table-head{
	background-color: #F2FBFF !important;
}

/* date picker css */

.react-datepicker__close-icon::after{
  margin-left: 20px !important;
  background-color: red !important;
}
.react-datepicker-time__caption{
  display: none !important;
}

.react-datepicker-time__input{
  margin-left: 0 !important;
}

.react-datepicker__input-time-container{
  padding: .5rem !important;
  width: 100% !important;
  border-top: 1px solid #dee2e6;
  margin: 0 !important;
}

.react-datepicker__month-container{
  border-right: 1px solid #dee2e6;
}

.react-datepicker__header {
  background-color: transparent !important;
  margin-top: .5rem !important;

}

.react-datepicker__navigation-icon{
  margin-top: 2rem !important;
}

.react-datepicker__current-month{
  font-size: 16px !important;
}

.react-datepicker__day-names{
  font-size: 14px !important;
}

.react-datepicker__header {
  border: none !important;
}

.react-datepicker__month{
  font-size: 14px !important;
}

.react-datepicker__day--today{
  background-color: #F2F4F7 !important;
  color: #000 !important;
  border-radius: 50% !important;
}
.react-datepicker__day--in-range{
  background-color: white !important;
  color: #000 !important;
  /* background-color: red !important; */
}

.react-datepicker__day--selected{
  background-color: #009EDB !important;
  color: #fff !important;
  border-radius: 50% !important;
}

.react-datepicker__day--range-end{
  background-color: #009EDB !important;
  color: #fff !important;
  border-radius: 50% !important;
}

.react-datepicker-time__input-container{
  width: 100% !important;
}

.react-datepicker-time__input{
  width: 100% !important;
}

//table sdg mapping
.w-250px{
  width: 250px !important;
}

.table-width-230{
  width: 230px !important;
}

.required-Chart-Height-Widht{
  // margin-left: 1rem !important;
  width: 180px !important;
  height: 300px !important;
}

.required-chart-text-width{
  width: 220px !important;
}
// .table-responsive-all{
//   overflow: auto !important;
// }

#barChartWidth{
  height: 360px !important;
}

/* chart scroll */

#chratScroll{
  overflow-x: scroll !important;
}

#chratScroll > canvas{
  height: 500px !important;
  width: 1700px !important;
}

@media screen and (max-width: 1980px) {
    .table-responsive-all{
      overflow: auto !important;
    }
}
